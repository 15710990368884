export default function Footer() {
    return (
        <div className="bg-[#1b1b1b] flex flex-col gap-2 p-4 lg:p-8">
            <div className="flex flex-row gap-3">
                <img src={require('../assets/icons/calipsio_logo_white.png')}
                    className="h-6" />
                <div className="text-2xl text-white">Calipsio</div>
            </div>
            <div className="text-white text-sm opacity-50">© 2024 Calipsio - All rights reserved</div>
        </div>
    )
}