import { HashLink } from 'react-router-hash-link';

export default function NavBar() {
    return (
        <div className="flex flex-row text-white items-center bg-[#1b1b1b] justify-between py-4 px-4 lg:px-12">
            <div className="flex flex-row gap-3">
                <img src={require('../assets/icons/calipsio_logo_white.png')}
                    className="h-6" />
                <div className="text-2xl">Calipsio</div>
            </div>
            <div className="flex flex-row gap-8 items-center">
                <HashLink smooth to="#mission" className="hidden sm:block"><div className="cursor-pointer hover:opacity-80">Mission</div></HashLink>
                <HashLink smooth to="#apps" className="hidden sm:block"><div className="cursor-pointer hover:opacity-80">Apps</div></HashLink>
                <a
                    href="mailto:contact@calips.io"
                    className="flex flex-row items-center p-2 bg-white cursor-pointer transition-all hover:opacity-90">
                    <div className="text-black">Contact us</div>
                    <span class="ml-4">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="w-5 h-5 fill-current"><path fill="black" d="M17.92,11.62a1,1,0,0,0-.21-.33l-5-5a1,1,0,0,0-1.42,1.42L14.59,11H7a1,1,0,0,0,0,2h7.59l-3.3,3.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0l5-5a1,1,0,0,0,.21-.33A1,1,0,0,0,17.92,11.62Z"></path>
                        </svg>
                    </span>
                </a>
            </div>
        </div>
    )
}