export default function AppOverview({
    icon = "",
    name = "",
    subtitle = "",
    description = "",
    url = "",
    screenshots = []
}) {
    return (
        <div className="flex basis-5/12 bg-white border flex-col divide-y divide-x-0 md:flex-row md:divide-y-0 md:divide-x">
            <div className="flex flex-1 flex-col divide-y">
                <div className="flex flex-row gap-4 items-center p-4 lg:p-8">
                    <img src={icon} className="h-16 bg-red-300 rounded-xl" />
                    <div className="text-left">
                        <h3 className="text-2xl">
                            {name}
                        </h3>
                        <p className="opacity-50">
                            {subtitle}
                        </p>
                    </div>
                </div>
                <div className="gap-8 flex flex-col flex-1 justify-between p-4 lg:p-8">
                    <div className="text-left">
                        <div>{description}</div>
                    </div>
                    <a
                        href={url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="cursor-pointer">
                        <img
                            className="h-14"
                            src="https://upload.wikimedia.org/wikipedia/commons/thumb/3/3c/Download_on_the_App_Store_Badge.svg/1280px-Download_on_the_App_Store_Badge.svg.png" />
                    </a>
                </div>
            </div>
            <div className="flex basis-7/12  gap-4 overflow-scroll no-scrollbar p-4 lg:p-8">
                {screenshots?.length > 0 && screenshots.map(screen =>
                    <div className="shrink-0">
                        <img
                            className="h-[520px] w-auto rounded-xl object-contain"
                            src={screen} />
                    </div>
                )}
            </div>
        </div>
    )
}