export default [
    {
        icon: "https://is1-ssl.mzstatic.com/image/thumb/Purple211/v4/5d/9f/70/5d9f7077-b3a4-ecfe-f153-6f684ca4a926/AppIcon-0-0-1x_U007emarketing-0-6-0-85-220.png/460x0w.webp",
        title: "CaloSnap",
        subtitle: "Your personal AI nutrition coach",
        description: "CaloSnap leverages AI to create personalized meal plans, track calories, and monitor progress, ensuring you stay on top of your health goals. With CaloSnap, you can easily make informed dietary choices and optimize your eating habits for better health and well-being.",
        link: "https://apps.apple.com/us/app/id6529549531",
        screenshots: [
            "https://is1-ssl.mzstatic.com/image/thumb/PurpleSource221/v4/a3/1c/f2/a31cf2f4-1127-d975-9335-560339444c93/735a2c1e-bf3f-450f-b0e0-c8ac92a1c8fb_67_1.jpg/460x0w.webp",
            "https://is1-ssl.mzstatic.com/image/thumb/PurpleSource211/v4/b0/a1/4f/b0a14f0c-3784-3e09-ac5b-b31aa0d81319/7a9993a8-7e69-4389-9464-0027300c91a5_67_2.jpg/460x0w.webp",
            "https://is1-ssl.mzstatic.com/image/thumb/PurpleSource221/v4/29/6c/c6/296cc653-2542-dab2-f614-2daf59d21536/200e454e-c2b1-451a-9672-36ff831f654b_67_3.jpg/460x0w.webp",
            "https://is1-ssl.mzstatic.com/image/thumb/PurpleSource221/v4/9c/6e/b7/9c6eb71c-d4d1-b9c3-2c45-4d4a424426da/798ce968-1a33-402f-9104-c06f0d9c45c0_67_4.jpg/460x0w.webp",
            "https://is1-ssl.mzstatic.com/image/thumb/PurpleSource221/v4/27/0c/75/270c75ef-faa1-2d6a-dbd1-9e3ed1d76bb1/c18701fb-e3d6-4fe3-9670-8a0aac9df9ce_67_5.jpg/460x0w.webp",
            "https://is1-ssl.mzstatic.com/image/thumb/PurpleSource211/v4/a1/da/16/a1da16f1-bd50-c470-b546-cca61e49a0d8/e42425e9-b442-405a-ba3b-ef812326e9a8_67_6.jpg/460x0w.webp"
        ]
    },
    {
        icon: require("./images/wod/icon.png"),
        title: "WOD Generator",
        subtitle: "Your personal CrossFit trainer",
        description: "WOD Generator offers a dynamic collection of high-intensity workouts designed to improve strength, endurance and overall fitness. Whether you're a beginner or an experienced athlete, generate customized workouts based on your goals and available equipment.",
        link: "https://apps.apple.com/us/app/id6670617154",
        screenshots: [
            require("./images/wod/1.jpg"),
            require("./images/wod/2.jpg"),
            require("./images/wod/3.jpg"),
            require("./images/wod/4.jpg"),
            require("./images/wod/5.jpg"),
            require("./images/wod/6.jpg"),
            require("./images/wod/7.jpg")
        ]
    },
    {
        icon: "https://is1-ssl.mzstatic.com/image/thumb/Purple221/v4/c9/8f/87/c98f87ec-4c6a-acd0-7b86-f7e6b36a932e/AppIcon-0-0-1x_U007emarketing-0-6-0-85-220.png/460x0w.webp",
        title: "Interview Copilot",
        subtitle: "Master your next job interview with AI-powered insights",
        description: "Interview Copilot helps job seekers prepare for their interviews by simulating real interview scenarios and providing personalized feedback. With AI-driven coaching, users can practice answers, improve their confidence, and fine-tune their performance for the best chances of landing their dream job.",
        link: "https://apps.apple.com/us/app/id6502905054",
        screenshots: [
            "https://is1-ssl.mzstatic.com/image/thumb/PurpleSource221/v4/19/66/08/19660851-bb5d-e29b-54b8-2d99d10d50c5/af6d31eb-5284-46cb-8eba-b97160c55fd0_67_1.jpg/460x0w.webp",
            "https://is1-ssl.mzstatic.com/image/thumb/PurpleSource211/v4/19/0c/a5/190ca573-a8a7-344e-8d94-faea0e2fe15a/1d2f0dd8-d77f-4c07-aece-eda790a8c9ab_67_2.jpg/460x0w.webp",
            "https://is1-ssl.mzstatic.com/image/thumb/PurpleSource221/v4/4c/c1/db/4cc1db60-c8c9-d53f-1b8f-deb9e28c401d/59609561-3719-4541-96ad-c133c4e18cae_67_3.jpg/460x0w.webp",
            "https://is1-ssl.mzstatic.com/image/thumb/PurpleSource211/v4/c4/64/93/c46493c3-40bf-91bc-686c-43170e1306b4/ae655ba9-a508-4185-a980-5e40734fe65b_67_5.jpg/460x0w.webp",
            "https://is1-ssl.mzstatic.com/image/thumb/PurpleSource211/v4/7e/4c/87/7e4c87b5-d032-26c1-8698-e598f88f560f/bf786b39-6f25-4776-8bf1-900a67c7d5f5_67_4.jpg/460x0w.webp",
            "https://is1-ssl.mzstatic.com/image/thumb/PurpleSource211/v4/b0/d6/11/b0d611b0-6303-513b-0fc2-40a93c0dcc30/82c0ddf5-04a2-4a78-8eb1-eebcb43f817c_67_6.jpg/460x0w.webp"
        ]
    },
    {
        icon: "https://is1-ssl.mzstatic.com/image/thumb/Purple211/v4/b5/1f/cb/b51fcb5b-5ed9-4798-2afb-b8d45892d17e/AppIcon4-0-0-1x_U007emarketing-0-6-0-85-220.png/460x0w.webp",
        title: "Guided Pilates",
        subtitle: "Transform your body with Pilates routines",
        description: "Discover expertly guided Pilates sessions for all levels. Whether you are a beginner or advanced practitioner, Pilates offers tailored workouts that focus on core strength, flexibility, and mindfulness. Each session is designed to fit seamlessly into your lifestyle, helping you achieve your fitness goals efficiently.",
        link: "https://apps.apple.com/us/app/id6468663772",
        screenshots: [
            "https://is1-ssl.mzstatic.com/image/thumb/PurpleSource211/v4/0e/8c/3e/0e8c3eef-f4fe-7566-5b65-e02285cf6bfc/af9bc988-449b-48c6-8421-a9bf45402f9f_67_1.jpg/460x0w.webp",
            "https://is1-ssl.mzstatic.com/image/thumb/PurpleSource211/v4/d5/27/d7/d527d71d-95cd-019f-18d8-38358520c62d/7ccd304b-a48a-45ea-93b0-be3a959a9e01_67_2.jpg/460x0w.webp",
            "https://is1-ssl.mzstatic.com/image/thumb/PurpleSource221/v4/38/ec/59/38ec59ef-ecae-6b1b-2e00-7baca26455c2/fb245897-90fc-4579-bd59-44dc3fc6036a_67_3.jpg/460x0w.webp",
            "https://is1-ssl.mzstatic.com/image/thumb/PurpleSource221/v4/f3/3c/06/f33c0649-6b06-6693-7882-b739f47cef7b/941593a4-b492-4ceb-9414-57860db2d21e_67_4.jpg/460x0w.webp",
            "https://is1-ssl.mzstatic.com/image/thumb/PurpleSource221/v4/e8/75/f2/e875f283-a0e3-15cb-0e12-6cd8dbfe59b1/639832b5-24e8-4582-aac3-852812cc84bf_67_5.jpg/460x0w.webp",
            "https://is1-ssl.mzstatic.com/image/thumb/PurpleSource211/v4/01/e0/13/01e013ee-e726-da2c-4b11-032ed0b569e1/50301b1b-846c-492b-a948-0b3e9b070299_67_6.jpg/460x0w.webp",
            "https://is1-ssl.mzstatic.com/image/thumb/PurpleSource211/v4/81/bc/c4/81bcc4dd-7ab3-07d4-938f-9d22b850b443/665388c3-a1d2-447c-8783-b67600fa743d_67_7.jpg/460x0w.webp"
        ]
    }
]