import React, { useEffect, useRef } from "react";

export default function Spotlight({
  eyebrow = "",
  title = ""
}) {
  const unstyledTitle = removeSpans(title)
  const cardsContainer = useRef(null)

  function removeSpans(children) {
    return React.Children.map(children, child => {
      if (React.isValidElement(child)) {
        if (child.type === 'span') {
          return removeSpans(child.props.children);
        } else {
          return React.cloneElement(child, {
            children: removeSpans(child.props.children),
          });
        }
      }
      return child
    })
  }

  const applyOverlayMask = (e) => {
    if (!cardsContainer.current) {
      return;
    }

    const x = e.pageX - cardsContainer.current.offsetLeft;
    const y = e.pageY - cardsContainer.current.offsetTop;

    cardsContainer.current.setAttribute(
      "style",
      `--x: ${x}px; --y: ${y}px; --opacity: 1`
    );
  };

  useEffect(() => {
    document.body.addEventListener("pointermove", (e) => {
      applyOverlayMask(e);
    });

    return () => {
      document.body.removeEventListener("pointermove", (e) => {
        applyOverlayMask(e);
      });
    };
  }, []);

  return (
    <>
      <main className="bg-[#1b1b1b] cursor-default">
        <div className="relative" ref={cardsContainer}>
          <section id="mission" className="py-20 px-4 lg:px-20 border-[#47494a] border border-r-0 sm:text-white/5 text-white/50">
            <div className="mb-4 opacity-90 ">
              {eyebrow}
            </div>
            <h2 className="text-3xl md:text-6xl md:leading-tight">
              {unstyledTitle}
            </h2>
          </section>

          {/* twin cards */}
          <div
            className="flex flex-wrap gap-10 select-none pointer-events-none absolute inset-0"
            style={{
              opacity: "var(--opacity, 0)",
              mask: `
                radial-gradient(
                  50rem 50rem at var(--x) var(--y),
                  #000 1%,
                  transparent 50%
                )`,
              WebkitMask: `
                radial-gradient(
                  50rem 50rem at var(--x) var(--y),
                  #000 1%,
                  transparent 50%
                )`,
            }}
          >
            <section id="mission" className="py-20 px-4 lg:px-20 bg-blue-500/25 border-blue-500 border">
              <div className="mb-4 opacity-90 text-blue-500">
                {eyebrow}
              </div>
              <h2 className="text-3xl text-blue-200/25 md:text-6xl md:leading-tight">
                {title}
              </h2>
            </section>


          </div>
        </div>
      </main>
    </>
  );
};