export default function ContactUs({
    title = ""
}) {
    return (
        <div className="py-36 px-4 lg:px-0">
            <div className="text-5xl sm:text-6xl text-white leading-snug">
                {title}
            </div>
            <div className="inline-block">
                <a
                    href="mailto:contact@calips.io"
                    className="flex flex-row items-center p-4 bg-[#1b1b1b] mt-8 cursor-pointer transition-all hover:opacity-90">
                    <div className="text-white">Contact us</div>
                    <span class="ml-4">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="w-5 h-5 fill-current"><path fill="white" d="M17.92,11.62a1,1,0,0,0-.21-.33l-5-5a1,1,0,0,0-1.42,1.42L14.59,11H7a1,1,0,0,0,0,2h7.59l-3.3,3.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0l5-5a1,1,0,0,0,.21-.33A1,1,0,0,0,17.92,11.62Z"></path>
                        </svg>
                    </span>
                </a>
            </div>
        </div>
    )
}