export default function Hero({
    title = "",
    subtitle = ""
}) {
    return (
        <div className="max-w-3xl">
            <h1 className="text-white leading-tight text-center text-5xl sm:text-8xl">
                {title}
            </h1>
            <div className="text-center text-xl text-white opacity-50 mt-12 leading-snug">
                {subtitle}
            </div>
        </div>
    )
}