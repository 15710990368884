import Spotlight from './components/Spotlight';
import NavBar from './components/NavBar';
import AnimatedBackground from './components/AnimatedBackground';
import Footer from './components/Footer';
import Hero from './components/Hero';
import AppOverview from './components/AppOverview';
import ContactUs from './components/ContactUs';
import apps from './assets/apps';

export default function App() {
  return (
    <div className="font-beatrice">
      <NavBar />
      <section className="py-32 px-4 bg-[#1b1b1b] justify-center flex lg:px-20">
        <Hero
          title={<span>Creating apps<br />tailored to<br /><span className="animated-background bg-gradient-to-r from-blue-500 via-pink-500 to-yellow-500 inline-block text-transparent bg-clip-text">enhance</span> lives</span>}
          subtitle="We craft mobile applications designed to improve everyday life,  empower users to achieve their goals, and deliver outstanding experiences."
        />
      </section>
      <AnimatedBackground>
        <Spotlight
          eyebrow="What we do"
          title={<span>The best apps are created with <span className="text-blue-100">passion</span>. We strive to create products we're proud of by focusing on <span className="text-blue-100">stunning user experiences</span>, <span className="text-blue-100">top-notch content</span> and <span className="text-blue-100">cutting-edge features</span>.</span>}
        />

        <section id="apps" className="flex flex-col bg-[#fcfbfb] py-20 gap-12 lg:gap-16 px-4 lg:pl-20 lg:pr-0">
          <h2 className="text-left text-4xl">Our apps</h2>
          <div className="flex flex-col gap-12 lg:gap-16">
            {
              apps.map(app =>
                <AppOverview
                  key={app.title}
                  name={app.title}
                  subtitle={app.subtitle}
                  description={app.description}
                  icon={app.icon}
                  screenshots={app.screenshots}
                  url={app.link}
                />
              )
            }
          </div>
        </section>
        <section>
          <ContactUs
            title={<span>An idea?<br />Co-create your app together</span>}
          />
          <Footer />
        </section>
      </AnimatedBackground>
    </div >
  )
}